import React from "react"
import { gql, useQuery } from '@apollo/client';
import { Table } from "react-bootstrap";

const GET_ANDROID_DEVICES = gql`
  query GetAndroidDevices {
    androidApps {
      id
      name
      version
      apk
    }
  }
`;

const AndroidApps = props => {
  const { loading, error, data } = useQuery(GET_ANDROID_DEVICES);

  if (loading) return 'Loading...';
  if (error) return `Error! ${error.message}`;

  return (
    <div>
      <h1>Android Apps</h1>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Id</th>
            <th>Name</th>
            <th>Version</th>
            <th>APK</th>
          </tr>
        </thead>
        <tbody>
          {data && data.androidApps.map(app => (
            <tr>
              <td>{app.id}</td>
              <td>{app.name}</td>
              <td>{app.version}</td>
              <td><a href={`/apks/${app.apk}`}>{app.apk}</a></td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}

export default AndroidApps
