import React from "react";
import { Container, Navbar, Nav, Row, Col } from "react-bootstrap";
import { Drawer, DrawerOverflow, DrawerToC, DrawerNavigation } from "react-bootstrap-drawer";

const DefaultNav = props => {
  return (
    <div id="wrapper">
      <Navbar bg="dark" variant="dark" expand="lg">
        <Container fluid>
          <Navbar.Brand href="/">Fastlottery Admin</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Container fluid>
        <Row className="flex-xl-nowrap">
          <Col xs={12} md={3} lg={2}>
            <Drawer>
                <DrawerOverflow>
                  <DrawerToC>
                    <DrawerNavigation>
                      <Nav.Item>
                        <Nav.Link href="/devices">Devices</Nav.Link>
                        <Nav.Link href="/apps">Apps</Nav.Link>
                      </Nav.Item>
                    </DrawerNavigation>
                  </DrawerToC>
                </DrawerOverflow>
            </Drawer>
          </Col>

          <Col xs={12} md={9} lg={10}>
            <div class="main-content">
              {props.children}
            </div>
          </Col>
        </Row>
      </Container>

    </div>

  )
}

export default DefaultNav
