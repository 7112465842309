import React from "react";
import ReactDOM from "react-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-drawer/lib/style.css";

import "./index.css";

import reportWebVitals from "./reportWebVitals";

import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import { BrowserRouter as Router, Switch } from "react-router-dom"

import DefaultLayout from "./layout/defaultLayout";

import Dashboard from "./components/dashboard"
import Devices from "./components/devices"
import AndroidApps from "./components/android_apps"

const client = new ApolloClient({
  uri: process.env.REACT_APP_ENDPOINT || "http://localhost:3000/graphql",
  cache: new InMemoryCache()
});


ReactDOM.render(
  <ApolloProvider client={client}>
    <React.StrictMode>
      <Router>
          <Switch>
            <DefaultLayout exact path="/" component={Dashboard} />
            <DefaultLayout exact path="/devices" component={Devices} />
            <DefaultLayout exact path="/apps" component={AndroidApps} />
          </Switch>
      </Router>
    </React.StrictMode>
  </ApolloProvider>,  
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
