import React from "react"
import { gql, useQuery } from '@apollo/client';
import { Table } from "react-bootstrap";

const GET_ANDROID_DEVICES = gql`
  query GetAndroidDevices {
    androidDevices {
      id
      name
      deviceId
      hardwareId
      lastCommunicationAt

      installedApps {
        name
        version        
      }
    }
    androidApps {
      package
      version
    }
  }
`;

const Devices = props => {
  const { loading, error, data } = useQuery(GET_ANDROID_DEVICES);

  if (loading) return 'Loading...';
  if (error) return `Error! ${error.message}`;

  return (
    <div>
      <h1>Devices</h1>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Device Id</th>
            <th>Hardware Id</th>
            <th>Name</th>
            <th>Last comm.</th>
            <th>Installed Apps</th>
          </tr>
        </thead>
        <tbody>
          {data && data.androidDevices.map(device => (
            <tr>
              <td>{device.deviceId}</td>
              <td>{device.hardwareId}</td>
              <td>{device.name}</td>
              <td>{(new Date(device.lastCommunicationAt)).toLocaleString()}</td>
              <td>
                <Table size="sm">
                  <tbody>
                    { data.androidApps.map(app => (
                      <tr>
                        <td>{app.package}</td>
                        <td>{app.version}</td>
                        <td>
                          {device.installedApps.find(iapp => iapp.name === app.package)?.version || "---"}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}

export default Devices
