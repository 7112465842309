import React from "react"
import { Route } from "react-router-dom"

import DefaultNav from "./defaultNav";

const DefaultLayout = ({ component: Component, ...rest }) => {
  return (
    <Route {...rest} render={props => (
      <DefaultNav>
        <Component {...props} />
      </DefaultNav>
    )} />
  )
}

export default DefaultLayout